import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { DragDropChangeData } from "../../../dragdropinterface/src/DragDropQuestionController";
export interface Choice {
  id: number;
  content: string;
}

export interface ProgramSubmitData {
  questionId: number;
  questionType: string;
  buckets: {
      bucket_id: string;
      choice_ids: Array<number>;
  }[]
  value: Array<number> | number | string;
  totalOptions: Array<Choice>
}
interface CourseAttributes {
  post_submission: boolean;
  id: number;
  title: string;
  description: string;
  about: string;
  thumbnail: string;
  pre_submission: boolean,
}

export interface CourseListType {
  id: string; 
  type: string;
  attributes: CourseAttributes;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

interface PageOptions {
  per_page: number;
  total_pages: number;
  current_page: number;
}

interface CoursePrePostType {
  id: number;
  name: string;
  pre_submission: boolean;
  post_submission: boolean;
}

interface CourseStatus {
  id: string; 
  type: string;  
  attributes: {
    id: number; 
    student_id: number;
    status: string; 
    mind_mastery_course_id: number;
  };
}
// Customizable Area End

export const configJSON = require("../config.js");
let config = require("../../../../framework/src/config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Data {
  // Customizable Area Start
  id: string;
  type: string;
  attributes: {
    session_topic: string;
    date: string;
    end_date: string;
    time: string;
    status: string;
    super_power: any;
  };
  // Customizable Area End
}

interface Sections {
  // Customizable Area Start
  id?: number;
  name?: string;
  status?: string;
  // Customizable Area End
}

export interface Guidelines {
  // Customizable Area Start
  id?: number;
  description?: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  exp_date: string;
  check_list: Data[];
  firstRowIndex: number;
  lastRowIndex: number;
  mpowerSection: number;
  headMessage: string;
  guidelines: Guidelines[];
  selectedSessionID: string;
  page: number;
  pageCount: number;
  sections: Sections[];
  openFormSubmitModal: boolean;
  snackbar: boolean;
  snackbarMsg: string;
  programFormSubmitData: any;
  prePostFormData: any;
  modalType: "success" | "warning" | "confirm" | "schedule";
  prePostSubmitted: boolean;
  isPageChange: boolean;
  totalPages: number;
  accordionState: any;
  courseId: any;
  questionNumber: number;
  isLoading: boolean;
  courseList: any;
  slectedCourse:Array<CourseListType>;
  coursePage: number;
  pageOptionData: PageOptions;
  coursePrePost: Array<CoursePrePostType>;
  preModal: boolean,
  postModal: boolean,
  courseStatusList: Array<CourseStatus>;
  // Customizable Area End
}
interface SS { 
  // Customizable Area Start
  // Customizable Area End
}
export default class MPowerController extends BlockComponent<Props, S, SS> {
  programFormSubmitApiCallId: string = "";
  getPrePostFormDataApiCallId: string = "";
  getAssignedCoursesApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];
    const courseIdValue = this.props.navigation.getParam("course_id") || "";
    this.state = {
      loading: false,
      exp_date: "",
      check_list: [],
      firstRowIndex: 0,
      lastRowIndex: 10,
      mpowerSection: 1,
      headMessage: "",
      guidelines: [],
      selectedSessionID: '',
      page: 1,
      pageCount: 1,
      sections: [],
      openFormSubmitModal: false,
      snackbar: false,
      snackbarMsg: "",
      programFormSubmitData: {},
      prePostFormData: [],
      modalType: "success",
      prePostSubmitted: false,
      isPageChange: false,
      totalPages: 1,
      accordionState: false,
      courseId: courseIdValue,
      questionNumber: 1,
      isLoading: false,
      courseList: [],
      slectedCourse: [],
      coursePage: 1,
      coursePrePost: [],
      pageOptionData: {
        per_page: 1,
        total_pages: 1,
        current_page: 1
      },
      preModal: false,
      postModal: false,
      courseStatusList: []
    };
    this.handleDragDropChange = this.handleDragDropChange.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          this.setState({ snackbar: true, snackbarMsg: responseJson.errors, programFormSubmitData: {} });
          return;
        }
        if (!responseJson.error) {
          this.handleResponses(apiRequestCallId, responseJson)

        } else {
          //Check Error Response
          if (this.getPrePostFormDataApiCallId === apiRequestCallId) {
            this.setState({ snackbar: true, snackbarMsg: responseJson.error, prePostFormData: [], programFormSubmitData: {} });
          }
          this.parseApiErrorResponse(responseJson);
        }
      }
      // Customizable Area End
    }
  }

  // Customizable Area Start
  getCourseDetailListApiCallId: string = "";
  getPrePostListApiCallId: string = "";
  getCourseDetailApiCallId: string = "";

  handleResponses = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.programFormSubmitApiCallId: this.handlePreProgramAfterSubmit(); break;
      case this.getPrePostFormDataApiCallId: this.handlePrePostProgramQuestions(responseJson); break;
      case this.getAssignedCoursesApiCallId: ; break;
      case this.getCourseDetailListApiCallId: this.setState({courseList:responseJson.data , pageOptionData:responseJson.page_options}); break;
      case this.getPrePostListApiCallId: this.setState({coursePrePost: responseJson.data}); break;
      case this.getCourseDetailApiCallId: this.setState({ courseStatusList: responseJson.data });break;
    }
  }

  async getMpowerPageData() {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      Authorization: localStorage.getItem("user_token")
    };

    const res = await this.apiCall(
      configJSON.dashboarApiMethodType,
      `${configJSON.mpowerChecksList}?page=${this.state.page}`,
      header
    );
    if (res) {
      this.setState({ check_list: res.data, pageCount: res?.page_options?.total_pages });
      return true;
    } else {
      return false;
    }
  }

  async apiCall(method: string, endpoint: string, headers: any) {
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;
    let response: any;
    try {
      if (headers) {
        response = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers
        });
      }
      let responseJson = await response.json();
      if (response.status === 200) {
        return responseJson;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Api Error", error);
      return false;
    }
  }

  async showGuidelines(
    session_id: string,
    status: string,
    session_topic: string
  ) {
    if (status == configJSON.MPower.Published) {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        Authorization: localStorage.getItem("user_token")
      };
      const res = await this.apiCall(
        configJSON.dashboarApiMethodType,
        configJSON.mpowerChecksList + `/${session_id}`,
        header
      );
      if (res) {
        localStorage.setItem('mpowerData', JSON.stringify({ 'session_id': session_id }));
        this.setState({
          headMessage: session_topic,
          guidelines: res.data.attributes.guidelines,
          sections: res.data.attributes.section
        });
        return true;
      } else {
        return false;
      }
    } else if (status === "completed") {
      alert('The assessment was submitted');
    }
  }

  handlePage = (page: number) => {
    this.setState({ page: page });
  };

  handlerBackEvent = () => {
    this.setState({ mpowerSection: this.state.mpowerSection - 1 });
    this.setState({ headMessage: "" });
  }

  handleClickSessionStart = async (sectionId: number | null) => {
    const existingData = JSON.parse(localStorage.getItem('mpowerData') || "{}");
    const newObj = {
      ...existingData,
      sectionId: sectionId
    };
    localStorage.setItem('mpowerData', JSON.stringify(newObj));
    window.location.href = `/student/Mpower/QuestionsView/${existingData.session_id}`;
  }

  handleSelectCourse = (value: number) => {
    this.setState({ courseId: value });
  }

  handleClearSelectedCourse = () => {
    this.setState({ courseId: "", prePostFormData: [] })
  }

  handleOpenDialog = () => {
    this.setState({ openFormSubmitModal: true, modalType: 'confirm' })
  }

  handleCloseSubmitSuccessModal = () => {
    this.setState({ openFormSubmitModal: false })
    if (this.state.prePostSubmitted) {
      this.setState({ prePostSubmitted: false })
      this.goTo("MindMasteryPage");
    }
  }

  handleProgramFormQuestions = (questionId: any, value: any, questionType: string) => {
    this.setState((prevState) => {
      const updatedAnswer = prevState.programFormSubmitData[questionId] || {};
      return {
        programFormSubmitData: {
          ...prevState.programFormSubmitData,
          [questionId]: {
            ...updatedAnswer,
            questionId,
            value,
            questionType
          }
        }
      }
    })
  }

  handlePrePostProgramQuestions = (responseJson: any) => {
    const submitData = this.state.isPageChange ? this.state.programFormSubmitData : {}
    const page = this.state.isPageChange ? this.state.page : 1
    this.setState({ programFormSubmitData: submitData, prePostFormData: responseJson.data, page: page, totalPages: responseJson.page_options.total_pages });
  }

  handlePreProgramAfterSubmit = () => {
    this.setState({ openFormSubmitModal: true, modalType: 'success', prePostSubmitted: true })
  }

  goTo = (module: string, params: Object = {}) => {
    if (this.props.navigation.navigate) {
      this.props.navigation.navigate(module, { ...params });
    }
  };

  goBack = () => {
    if (this.props.navigation.goBack) {
      this.props.navigation.goBack();
    }
  };

  getToken = () => {
    return localStorage.getItem('user_token')
  }

  closeSnackBar = () => {
    this.setState({ snackbar: false })
  }

  navigateToForms = (type: string) => {
    if (type === 'Pre Program') {
      this.goTo('ProgramForm', { mPowerFormType: configJSON.YouMatterTexts.PreProgramType ,course_id:this.state.courseId})
    } else {
      this.goTo('ProgramForm', { mPowerFormType: configJSON.YouMatterTexts.PostProgramType,course_id:this.state.courseId })
    }
  }

  handleChangeAccordion = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ accordionState: isExpanded ? panel : false, questionNumber: Number(panel) });
  };

  handleDragDropChange(data: DragDropChangeData) {
    const updatedAnswer = {
      "questionId": data.question_id,
      "questionType": "drag_and_drop",
      "buckets": [
          {
              "bucket_id": data.correct_bucket_items.bucket_id,
              "choice_ids": data.correct_bucket_items.choices,
              "is_correct": true,
          },
          {
              "bucket_id": data.incorrect_bucket_items.bucket_id,
              "choice_ids": data.incorrect_bucket_items.choices,
              "is_correct": false
          }
      ],
      "value": [...data.correct_bucket_items.choices, ...data.incorrect_bucket_items.choices],
      "totalOptions": this.state.prePostFormData.find((question: any) => question.id == data.question_id).attributes.choices
  }

  this.setState({ programFormSubmitData: { ...this.state.programFormSubmitData, [data.question_id]: { ...updatedAnswer } } });
  }

  getPrePostQuestions = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken(),
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPrePostFormDataApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.PrePostProgramGetApiEndPoint}?mind_mastery_course_id=${this.state.courseId}&page=${this.state.page}`,
      header
    );
  }

  handlePerAndPost = () => {
    this.setState({postModal: true})
  }

  getAssignedCourses = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken(),
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAssignedCoursesApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.YouMatterTexts.PrePostEndPoint,
      header
    );
  }

  submitPrePostForm = async (formType: any) => {
    this.setState({ openFormSubmitModal: false })
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken(),
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.programFormSubmitApiCallId = apiRequest.messageId;
    const data = {
      pre_post_form_form_answers: Object.values(this.state.programFormSubmitData).map((item: any) => {
        const isBoolean = item.questionType === configJSON.QuestionTypes.TrueFalse;
        let answerKey = isBoolean ? configJSON.YouMatterTexts.StudentSubmission : configJSON.YouMatterTexts.ChoiceId
        answerKey = item.questionType === 'drag_and_drop' ? 'buckets': answerKey;
        const answerValue = isBoolean ? item.value : Number(item.value) || item.buckets;
        return {
          mind_mastery_course_id: this.state.courseId,
          question_id: item.questionId,
          [answerKey]: answerValue,
          form_type: formType
        }
      })
    }

    await this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardPostApiMethod,
      configJSON.PrePostProgramSubmitEndPoint,
      header,
      JSON.stringify(data)
    );
  }

  async makeApiCall(uniqueApiCallId: string, method: string, endpoint: string, headers: any, body?: any) {
    let fullURL = endpoint.indexOf("://") === -1 ? config.baseURL + "/" + endpoint : endpoint;
    let apiResponseMessage = new Message(getName(MessageEnum.RestAPIResponceMessage));
    apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceDataMessage), uniqueApiCallId);
    try {
      this.setState({ isLoading: true })
      let response = await fetch(fullURL, { method: method.toUpperCase(), headers: headers, body: body })
      if (response.status === 401) { localStorage.removeItem('userDetails'); this.goTo('LoginForm') }
      this.setState({ isLoading: false })
      let responseJson = await response.json();
      if (responseJson.data || responseJson.message) { this.setState({ isLoading: false }) }
      apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), responseJson);
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceErrorMessage), "An error has occuured. Please try again later.");
    }
    this.send(apiResponseMessage);
  }

  handleCourseChange = (courseId: number | string) => {
    const selectedCourses = this.state.courseList.filter(
      (course: CourseListType) => course.id.toString() === courseId.toString()
    );
    this.setState({ mpowerSection: 2, courseId: courseId, slectedCourse: selectedCourses });
  };

  handleperQuestionType = (type: string) => {
    const courseFound = this.handlePreSubmmission();
    let isPreSubmission = courseFound?.pre_submission;
    let isPostSubmission = courseFound?.post_submission
    if(type === "Pre Program"){
      if(isPreSubmission){
        this.setState({preModal: true})
      }
      else{
        this.navigateToForms(type);
      }
    } 
    else{
      if(isPostSubmission){
        alert("Post program is already submitted")
      }
      else{
        this.navigateToForms(type)
      }
    }
  }

  handlePreSubmmission = () => {
    const { coursePrePost, courseId } = this.state;
    const foundItem = coursePrePost.find((item) => item.id === courseId);
    return foundItem;
  }


  apiEditCall = async (apiData: APIPayloadType) => {

    let token = await localStorage.getItem('user_token')

    const { contentType, method, endPoint, body } = apiData;
    
    const header = {
      "Content-Type": contentType,
      "Authorization": token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body))
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  onPressSignInCallID = async (page:number) => {
    this.getCourseDetailListApiCallId = await this.apiEditCall({
      contentType: "application/json",
      method: configJSON.dashboardGetApiMethod,
      endPoint: `${configJSON.getCourseListApiPreAndPostEndPoint}?page=${page}&per_page=6`,
    }); 
  };

  getPerAndPostDetails = async () => {
    this.getPrePostListApiCallId = await this.apiEditCall({
      contentType: "application/json",
      method: configJSON.dashboardGetApiMethod,
      endPoint: "pre_post_courses",
    }); 
  };

  handlePageCourse = (page: number) => {
    this.setState({ coursePage: page },() => {this.onPressSignInCallID(this.state.coursePage)});
  };

  handleClosePreModal = () => {
    const courseFound = this.handlePreSubmmission();
    let isPreSubmission = courseFound?.pre_submission;
    if (!isPreSubmission) {
      this.goTo('ProgramForm', { mPowerFormType: configJSON.YouMatterTexts.PreProgramType ,course_id:this.state.courseId})
      this.setState({ preModal: false, postModal: false });
    }
    else {
      this.setState({ preModal: false, postModal: false });
      this.goTo("MindMasteryPage");
    }
  }

  onGetCourseStatus = async () => {
    this.getCourseDetailApiCallId = await this.apiEditCall({
      contentType: "application/json",
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.MindMasteryTexts.courseDetails,
    }); 
  };

  getStatus = (courseId: number) => {
    const course = this.state.courseStatusList.find((item) => item.attributes.mind_mastery_course_id === courseId);
    if (course) {
      if (course.attributes.status === "completed") {
        return true;
      }
    }
    return false;
  };

  // Customizable Area End
}
