import React from "react";
// Customizable Area Start
import MPowerController, { Props, S ,CourseListType } from "./MPowerController";
import MpowerGuidelines from "./MpowerGuidelines.web";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Grid, styled, Card, CardActions, CardContent, CardMedia, Typography } from "@material-ui/core";
import SidebarLayout from "../SidebarLayout.web";
import { playMpower } from "../assets";
import PageContentLayout from "../PageContentLayout.web";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CommonPagination from "../../../../components/src/Pagination.web";
import LockIcon from '@material-ui/icons/Lock';
import InfoModal from "../CommonComponents/InformationModal.web";
// Customizable Area End
const configJSON = require("../config.js");

// Customizable Area Start
// Customizable Area End


export default class MPower extends MPowerController {
  // Customizable Area Start

  async componentDidMount() {
    this.getMpowerPageData();
    this.onPressSignInCallID(this.state.coursePage);
    this.getPerAndPostDetails()
    this.onGetCourseStatus()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.page !== this.state.page) {
      this.getMpowerPageData()
    }
  }

  getSuperPowerData = (super_power: any) => {
    if (super_power) {
      return (
        <>
          <img style={{ width: '35px' }} src={super_power.attributes.image} alt="super power" />
        </>
      );
    } else {
      return "-";
    }
  };

  getPerAndPost = () => {
    const statusForPost = this.getStatus(this.state.courseId);
    return (
      <>
        <PreStyle>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={6}>
              <Box className="boxBody" data-test-id="preTestId" onClick={() => this.handleperQuestionType("Pre Program")}>Pre Program </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Box className="boxBodys" data-test-id="postTestId" onClick={statusForPost ? (() => this.handleperQuestionType("Post Program")) :this.handlePerAndPost}>
                {statusForPost ? <LockOpenIcon className="lockIcon" /> : <LockIcon className="lockIcon" />}
                Post Program
              </Box>
            </Grid>
          </Grid>
        </PreStyle>
      </>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      tableHeader,
      tableHeadCell,
      boxPadding
    } = tableStyle;

    const TableHeading = configJSON.MPower.TableHeader;

    const guidelinesData = {
      guidelines: this.state.guidelines,
      sections: this.state.sections,
      handleClickSessionStart: this.handleClickSessionStart,
    };
    return (
      <SidebarLayout {...this.props}>
        <PageContentLayout title={`MPower Check ${this.state.headMessage && ' - ' + this.state.headMessage}`} subtitle={"Skill & Competency Check"} goBack={this.state.mpowerSection === 2 ? this.handlerBackEvent : null} handleFormDropDown={this.navigateToForms} data-test-id='page-layout' >
          {this.state.mpowerSection === 1 && (
            <Box style={boxPadding} data-test-id="pageContentTestId">
              <CourseData>
                <Grid container spacing={4}>
                  {this.state.courseList.length > 0 ? 
                  this.state.courseList?.map((element: CourseListType) => {
                    const { title, thumbnail, id } = element.attributes;
                    return (
                      <Grid
                        item
                        className="gridItem"
                        key={element.id}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={3}
                      >
                        <Card className="cardWrapper" data-test-id={`courseChangeTestId${id}`} onClick={() => this.handleCourseChange(id)}>
                          <CardMedia
                            className="cardMedia"
                            image={thumbnail}
                          />
                          <CardContent className="cardContent">
                            <Typography className="cardTitle">
                              {title}
                            </Typography>
                          </CardContent>
                          <CardActions>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  }): "No Pre Program Found"}
                </Grid>
                <Box className="paginationWrapper">
                  <CommonPagination page={this.state.coursePage} pageCount={this.state.pageOptionData?.total_pages} handlePage={this.handlePageCourse} />
                </Box>
              </CourseData>
            </Box>
          )}
          {this.state.mpowerSection === 2 && (
             <Box style={boxPadding}>
               {this.getPerAndPost()}
             </Box>
          )}
          <InfoModal
            data-test-id='sucessModalTestId'
            info={this.state.preModal?"Pre program is already submitted , you can start course":configJSON.MPower.PreSubmission}
            modalType="warning"
            open={this.state.preModal?this.state.preModal:this.state.postModal}
            closeModal={this.handleClosePreModal} />
        </PageContentLayout>
      </SidebarLayout>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const tableStyle = {
  table: {
    borderSpacing: "0 10px",
    borderCollapse: "initial",
    borderRadius: "20px",
    padding: "1px",
    marginBottom: '30px'
  } as React.CSSProperties,
  tableRow: {
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    background: "#fff",
    borderRadius: "10px",
    outline: "1px solid #ddd",
  } as React.CSSProperties,
  headerBox: {
    background:
      "linear-gradient(70deg, rgba(1,86,162,1) -36.53%, rgba(75,144,205,1) 66%, rgba(179,219,255,1) 146%)",
    borderRadius: "12px 12px 0px 0px",
    padding: "16px 24px",
    display: "flex",
    alignItems: "center",
  },
  mainBox: {
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: "12px",
    maxWidth: "1108px",
  },
  boxPadding: {
    padding: "24px",
  },
  mainTable: {
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
  },
  tableHeader: {
    backgroundColor: "0056A3",
    color: "#fff",
    borderRadius: "10px",
    "&:lastChild": {
      borderRadius: " 0 10px 10px 0",
    },
  },
  tableHeadCell: {
    color: "#fff",
    "&:lastChild": {
      borderRadius: " 0 10px 10px 0",
    },
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
};

const PreStyle = styled(Box)({
  display:"flex",
  "& .boxBody": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    fontSize: "30px",
    background: "linear-gradient(-131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%)",
    borderRadius: "25px",
    color: "white",
    fontWeight: "bold",
    position: "relative",
    cursor:"pointer"
  },
  "& .boxBodys": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    fontSize: "30px",
    background: "linear-gradient(-131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%)",
    borderRadius: "25px",
    color: "white",
    fontWeight: "bold",
    position: "relative",
    cursor:"pointer"
  },
  "& .lockIcon": {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "50px",
    color:"white"
  }
});


const CourseData = styled(Box)({
  cursor:"pointer",
  "& .paginationWrapper": {
    marginTop: '30px'
  },
  "& .mindMasteryWrapper": {
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "2px 10px 16px rgba(0, 0, 0, 0.0908271)",
    borderRadius: "12px",
  },
  "& .topBar": {
    display: "flex",
    alignItems: "center",
    background:
      "linear-gradient(131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%)",
    borderRadius: "12px 12px 0px 0px",
    height: "80px",
  },
  "& .backButton": {
    "&.MuiButton-root": {
      padding: "0",
      background: "#010E36",
      height: "48px",
      width: "48px",
      opacity: 0.5,
      borderRadius: "8px",
      margin: "0 24px",
      minWidth: "auto",
    },
  },
  "& .mindMasteryPageContent": {
    padding: "32px",
  },
  "& .cardMedia": {
    "&.MuiCardMedia-root": {
      height: "185px",
    },
  },
  "& .cardWrapper": {
    "&.MuiCard-root": {
      width:"100%",
      borderRadius: "8px",
      border: "0.2px solid #e3e3e3",
    },
    "&.MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  "& .cardTitle": {
    color: "#0056A3",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "18px",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkitLineClamp": 1,
    "-webkitBoxOrient": "vertical",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    "&:hover": {
      "-webkitBoxOrient": "inherit",
      wordBreak: "break-word",
    },
  },
  "& .startCourseBtn": {
    "&.MuiButton-root": {
      color: "white",
      fontFamily: "Poppins",
      width: "262px",
      height: "3px",
      background: "white",
      borderRadius: "8px",
      margin: "0 0px 10px auto",
      textTransform: "capitalize",
    },
  },
  "& .cardDescription": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#3B3B3B",
    minHeight: "141px",
  },
  "& .cardContent": {
    "&.MuiCardContent-root": {
      padding: "20px 24px 16px 24px",
    },
  },
  "& ,gridItem": {
  },
  paginationWrapper: {
    marginTop: "30px",
  },
});

// Customizable Area End
