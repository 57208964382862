import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
    getName
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';

let config = require('../../../../framework/src/config');
// Customizable Area Start
const navigation = require("react-navigation");
// Customizable Area End

export const configJSON = require('../config.js');
// Customizable Area Start
import { DragDropChangeData } from "../../../dragdropinterface/src/DragDropQuestionController";

export interface YouMatterQuestionPageProps {
    navigation: typeof navigation,
    id: string
    data: any;
    questionNumber: number;
    isListView: boolean;
    accordionState: any;
    currentTab: any;
    selectedAnswers: any;
    sectionsList: any;
    handleSubmit: () => void;
    handleTab: (_event: React.ChangeEvent<{}>, newValue: number) => void;
    handleOptionAnswer: (
        id: any,
        value: any,
        selectedOption: string,
        questionType: string
    ) => void;
    openUploadAttachment: (questionId: any, questionIndex: any) => void;
    handleQuestionChange: (page: number) => void;
    handleSubjectiveQuestion: (
        questionId: any,
        value: any,
        questionType: any
    ) => void;
    handleChangeAccordion: (
        panel: string
    ) => ((event: React.ChangeEvent<{}>, expanded: boolean) => void) | undefined;
    handleDragAndDropAnswer: (data: DragDropChangeData) => void;
}

interface sectionAnswerBucket {
    bucket_id: string | number;
    choice_ids: Array<number>;
}

interface sectionAnswer {
    question_id: string | number;
    question_type?: string;
    mpower_checks_section_id: string | number;
    document_file?: string;
    choice_id?: string | number | number[];
    solution?: string | number;
    buckets?: sectionAnswerBucket[];
}

export interface Options {
    content: string;
    id: any;
    is_selected: boolean;
}

export interface AnswerInputProps {
    type: string;
    options: Options[];
    isListView: boolean;
    questionId: any;
    selectedAnswers: any;
    handleOptionAnswer: (
        id: any,
        value: any,
        selectedOption: string,
        question: string
    ) => void;
    handleSubjectiveQuestion: (
        questionId: any,
        value: any,
        questionType: any
    ) => void;
    handleDragAndDropAnswer: (data: DragDropChangeData) => void;
    image?: string;
    rows?: number;
    columns?: number;
    refImage?: string;
    no_of_pieces?: any;
    buckets?: Bucket;
    navigation: typeof navigation;
    id: string;
}

export interface OptionProps {
    content: string;
    option: number;
    isListView: boolean;
}

export interface AttachmentAndTextProps {
    openAttachmentModal: (questionId: any, questionIndex: any) => void;
    questionId: any;
    image: any;
    index: number;
    type: string;
    selectedAnswers: any;
}

interface AnswerBucket {
    bucket_id: string;
    choices: number[];
    is_correct: boolean;
}

export interface QuestionAttributes {
    id: number;
    text: string;
    question_type: string;
    choice?: Options[];
    document_file: any;
}

export interface QuestionInfo {
    id: string;
    type: string;
    attributes: QuestionAttributes;
}

export interface QuestionCardProps {
    isListView: boolean;
    selectedAnswers: any;
    index: number;
    questionInfo: QuestionInfo;
}

export interface Navigation {
    navigate?: (path: string, params: Object) => void;
    goBack?: () => void;
    getParam?: (param: any) => any
}

export interface BucketData {
    id: string;
    type: string | 'bucket';
    attributes: {
        id: string | number,
        question_id: number,
        content: string | "Bucket" | null
        is_correct: boolean
    }
}

export interface Bucket {
    data: BucketData[]
}

export interface ChoiceOption {
    id: number;
    content: string; 
}

interface Trainer {
    id: number;
    full_name: string;
    profile_photo: any;
    email: any;
}

export interface Attributes {
    title: string;
    created_at: string;
    course_name: string;
    due_date: string;
    submission_status: string;
    submission_date: string;
    trainer: Trainer;
    you_matter_details: string;
}

export interface Data {
    id: string;
    type: string;
    attributes: Attributes
}

interface CourseSessionStatus {
    id: string;
    type: string;
    attributes: {
        id: number;
        student_id: number;
        mind_mastery_course_id: number;
        course_session_id: number;
        you_matter_id: number;
        enhance: string;
        ponder: string;
        engage: string;
        you_matter: string;
        status: string;
    };
}

export interface YouMatterPageProps {
    handleTabChange: (value: number) => void;
    data: Data[];
    openYouMatterModel: (attributes: Object, id: any) => void,
    pageCount: number;
    page: number;
    handlePage: (page: number) => void;
    submissionStatus: string;
}

export interface YouMatterCardProps {
    attributes: Attributes;
    youMatterId: any;
    openYouMatterModel: (attributes: Object, id: any) => void,
}

export interface SingleYouMatter {
    id: string;
    type: string;
    attributes: Attributes;
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
  }

interface SessionIdType {
    courseId: number;
    sessionId: number;
}

export interface YouMatterScore {
    you_matter_score: string,
    badge: string,
    badge_image: string,
}

interface CourseStatus {
    id: string;
    type: string;
    attributes: {
        id: number;
        student_id: number;
        status: string;
        mind_mastery_course_id: number;
    };
}

interface CoursePrePostType {
    id: number;
    name: string;
    pre_submission: boolean;
    post_submission: boolean;
  }

export interface Props {
    navigation: Navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
export interface S {
    // Customizable Area Start
    sessionIdStore: SessionIdType;
    youMatterScore: YouMatterScore | null;
    courseSessionStatusList: Array<CourseSessionStatus>;
    modalOpenForYouMatter: boolean;
    redirectionCourseId: number;
    courseStatusList: Array<CourseStatus>;
    coursePrePost: Array<CoursePrePostType>;
    // Customizable Area End
    youMatterPageData: any;
    page: number;
    submissionStatus: string;
    openYouMatterDetailsModel: boolean;
    youMatterDetails: any;
    isLoading: boolean;
    listView: boolean;
    openAttachmentDropZone: boolean;
    progress: number;
    attachmentName: string;
    attachmentSize: any;
    tempAttachmentUrl: string;
    answerAttachment: any;
    attachmentQuestionNumber: any;
    attachmentQuestionId: any;
    questionNumber: number;
    accordionState: any;
    youMatterId: any;
    openFormSubmitModal: boolean;
    answers: any;
    sections: any;
    sectionTab: any;
    sectionData: any;
    modalType: "success" | "warning" | "confirm" | "schedule";
    sectionOver: boolean;
    youMatterTitle: any;
    snackbar: boolean;
    snackbarMsg: string;
    isPageChange: boolean;
    totalPages: number;
}
interface SS { }
// Customizable Area End
export default class YouMatterController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    youMatterApiCallId: any;
    youMatterDetailsApiCallId: any;
    programFormSubmitApiCallId: any;
    youMatterSectionsApiCallId: any;
    sectionDataApiCallId: any;
    sectionFormSubmitApiCallId: any;
    getPrePostFormDataApiCallId: any;
    getAssignedCoursesApiCallId: any;
    getSessionStatusApiCallId: string = "getSessionStatusApiCallId";
    patchSessionStatusApiCallId: string = "patchSessionStatusApiCallId";
    youMatterScoreApiCallId: string = 'youMatterScoreApiCallId';
    getAllSessionStatusApiCallId: string = "getAllSessionStatusApiCallId";
    getCourseDetailApiCallId: string = "getCourseDetailApiCallId";
    getPrePostListApiCallId: string = "getPrePostListApiCallId"
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage)
        ];

        this.state = {
            youMatterPageData: {},
            youMatterDetails: {},
            page: 1,
            submissionStatus: 'published',
            openYouMatterDetailsModel: false,
            isLoading: false,
            listView: false,
            openAttachmentDropZone: false,
            progress: 0,
            attachmentName: '',
            attachmentSize: '',
            tempAttachmentUrl: '',
            answerAttachment: '',
            attachmentQuestionNumber: '',
            attachmentQuestionId: '',
            questionNumber: 1,
            accordionState: false,
            youMatterId: '',
            openFormSubmitModal: false,
            answers: {},
            sections: [],
            sectionTab: 0,
            sectionData: {},
            modalType: "success",
            sectionOver: false,
            youMatterTitle: '',
            snackbar: false,
            snackbarMsg: "",
            isPageChange: true,
            totalPages: 1,
            sessionIdStore: {
                courseId:0,
                sessionId:0
            },
            youMatterScore: null,
            courseSessionStatusList: [],
            modalOpenForYouMatter: false,
            redirectionCourseId: 0,
            courseStatusList: [],
            coursePrePost: []
        };

        this.handleYouMatterScore = this.handleYouMatterScore.bind(this);
        this.getAllSessionStatus = this.getAllSessionStatus.bind(this);
        this.handleRedirctionData = this.handleRedirctionData.bind(this);
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (!responseJson.error) {
                    this.handleResponses(apiRequestCallId, responseJson)
                } else {
                    //Check Error Response
                    this.parseApiErrorResponse(responseJson);
                }
            }
        }
    }

    handleResponses = (apiRequestCallId: any, responseJson: any) => {
        switch (apiRequestCallId) {
            case this.youMatterApiCallId: this.setState({ youMatterPageData: responseJson }); break;
            case this.youMatterSectionsApiCallId:
                const pendingSections = responseJson.data.attributes.sections.findIndex((item: any) => item.status === "pending");
                this.setState({ sections: responseJson.data.attributes.sections, youMatterTitle: responseJson.data.attributes.title, sectionTab: pendingSections });
                break;
            case this.sectionDataApiCallId: this.setState({ sectionData: responseJson.data }); break;
            case this.sectionFormSubmitApiCallId: this.handleYouMatterSections(); break;
            case this.youMatterScoreApiCallId: this.handleYouMatterScore(responseJson); break;
            case this.patchSessionStatusApiCallId: this.onGetCourseStatus();break;
            case this.getAllSessionStatusApiCallId: this.handleRedirctionData(responseJson.data); break;
            case this.getCourseDetailApiCallId: this.setState({ courseStatusList: responseJson.data }); break;
            case this.getPrePostListApiCallId: this.setState({coursePrePost: responseJson.data}); break;
        }
    }

    handleYouMatterSections = async () => {
        await this.handleFetchYouMatterScore();
        const sessionId = Number(localStorage.getItem("courseSessionId"));
        await this.handleParticularSession(sessionId);
    }

    handleYouMatterScore(youMatterScore: YouMatterScore) {
        this.setState({ youMatterScore: youMatterScore, openFormSubmitModal: true, modalType: configJSON.YouMatterTexts.Success, questionNumber: 1, sectionData: {}, answers: {}, attachmentQuestionId: '', attachmentQuestionNumber: '', accordionState: '' });
        if (this.state.sectionTab + 1 !== this.state.sections.length) {
            this.setState({ sectionTab: this.state.sectionTab + 1 });
        } else {
            this.setState({ sectionOver: true })
        }
    }

    goTo = (module: string, params: Object = {}) => {
        if (this.props.navigation.navigate) {
            this.props.navigation.navigate(module, { ...params });
        }
    };

    goBack = () => {
        if (this.props.navigation.goBack) {
            this.props.navigation.goBack();
        }
    };

    getToken = () => {
        return localStorage.getItem('user_token')
    }

    handlePage = (page: number) => {
        this.setState({ page: page })
    }

    handleTabChange = (value: number) => {
        if (value === 0) {
            this.setState({ submissionStatus: configJSON.YouMatterTexts.Published, page: 1 })
        }
        else {
            this.setState({ submissionStatus: configJSON.YouMatterTexts.Submitted, page: 1 })
        }
    };

    handleSectionTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ sectionTab: newValue })
    };

    openYouMatterDetailsModel = (attributes: any, youMatterId: any) => {
        this.setState({ openYouMatterDetailsModel: true, youMatterDetails: attributes, youMatterId: youMatterId });
    }

    closeYouMatterDetailsModel = (matterId: number, status: string, courseSessionId: number | string) => {
        localStorage.setItem("courseSessionId",JSON.stringify(courseSessionId))
        const sessionId = Number(localStorage.getItem("courseSessionId"))
        const checkAns = this.checkYouMatterAllowedOrNot(sessionId)
        if(checkAns){
            if (status === configJSON.YouMatterTexts.Published) {
                this.goTo(configJSON.YouMatterTexts.YouMatterQuestions, { youMatterId: matterId });
            }
        } else {
            this.setState({ openYouMatterDetailsModel: false });
            this.setState({ modalOpenForYouMatter: true });
        }
        this.setState({ openYouMatterDetailsModel: false });
    }

    handleCloseCourseStatus = () => {
        const courseId = this.state.redirectionCourseId
        if (courseId !== 0) {
            this.goTo("CourseDetailsPage", { courseId });
        } else {
            this.goTo("MindMasteryPage")
        }
        this.setState({ modalOpenForYouMatter: false })
    }

    checkYouMatterAllowedOrNot = (sessionId: number): boolean => {
        const foundItem = this.startYouMatter(sessionId);
        if (foundItem && foundItem.attributes) {
            this.setState({ redirectionCourseId: foundItem.attributes.mind_mastery_course_id })
            const status = foundItem.attributes.status;
            return status === "completed";
        }
        return false;
    }

    handleDialogueBox = () => {
        this.setState({ openYouMatterDetailsModel: false })
    }

    handleQuestionViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ listView: event.target.checked });
    };

    handleOpenDropZone = (questionId: any, questionIndex: any) => {
        this.setState({ openAttachmentDropZone: true, attachmentQuestionId: questionId, attachmentQuestionNumber: questionIndex });
    }

    handleCloseDropZone = () => {
        this.setState({ openAttachmentDropZone: false, attachmentName: '', attachmentSize: '', progress: 0, tempAttachmentUrl: '' })
    }

    handleCloseSubmitSuccessModal = () => {
        this.setState({ openFormSubmitModal: false });
        const sessionId = Number(localStorage.getItem("courseSessionId"));
        const foundItem = this.startYouMatter(sessionId);
        const courseId = foundItem?.attributes.mind_mastery_course_id
        const status = this.getStatus(Number(courseId))
        if (this.state.sectionOver) {
            this.setState({ sectionOver: false });
            if (status === "completed") {
                this.goTo("ProgramForm", { mPowerFormType: "Post Program", course_id: courseId });
            }
            else {
                this.goTo("CourseDetailsPage", { courseId: courseId })
            }
        }
    }

    async handleRedirctionData(response: Array<CourseSessionStatus>) {
        this.setState({ courseSessionStatusList: response });
    }

    getStatus = (courseId: number) => {
        const course = this.state.courseStatusList.find((item) => item.attributes.mind_mastery_course_id === courseId);
        if (course) {
            return course.attributes.status;
        }
        return "unstarted";
    }

    handleOpenDialog = () => {
        this.setState({ openFormSubmitModal: true, modalType: 'confirm' })
    }

    handleQuestionAnswer = (questionId: any, ansObj: any, selectedOption: string, questionType: string) => {
        this.setState((prevState) => ({
            answers: {
                ...prevState.answers,
                [questionId]: {
                    questionId: questionId,
                    selectedOption: selectedOption,
                    value: ansObj[0].id,
                    content: ansObj[0].content,
                    questionType: questionType
                }
            },
        }))
    }

    handleSubjectiveQuestion = (questionId: any, value: any, questionType: any) => {
        this.setState((prevState) => {
            const updatedAnswer = prevState.answers[questionId] || {};
            return {
                answers: {
                    ...prevState.answers,
                    [questionId]: {
                        ...updatedAnswer,
                        questionId: questionId,
                        value: value,
                        questionType
                    }
                }
            }
        })
    }

    handleUploadAttachment = async (event: any) => {
        const file = event[0];
        const fileSize = file.size;
        const fileName = file.name;
        const size = this.formatFileSize(fileSize);
        const url = URL.createObjectURL(file);
        this.setState((prevState) => {
            const updatedAnswer = prevState.answers[this.state.attachmentQuestionId] || {};
            return {
                tempAttachmentUrl: url,
                attachmentName: fileName,
                attachmentSize: size,
                progress: 0,
                answers: {
                    ...prevState.answers,
                    [this.state.attachmentQuestionId]: {
                        ...updatedAnswer,
                        attachmentName: fileName,
                        questionId: this.state.attachmentQuestionId,
                        attachment: file
                    }
                },
            }
        }, () => this.simulateProgress());
    }

    handleAttachmentUploadDone = () => {
        this.handleCloseDropZone();
    }

    simulateProgress = () => {
        let progressCounter = 0;
        const progressInterval = setInterval(() => {
            progressCounter += 10;
            if (progressCounter <= 100) {
                this.setState({ progress: progressCounter });
            } else {
                clearInterval(progressInterval);
            }
        }, 10);
    };

    handleCancelUpload = () => {
        this.setState((prevState) => {
            const updatedAnswer = prevState.answers[this.state.attachmentQuestionId] || {};
            return {
                attachmentName: '',
                attachmentSize: '',
                progress: 0,
                tempAttachmentUrl: '',
                answers: {
                    ...prevState.answers,
                    [this.state.attachmentQuestionId]: {
                        ...updatedAnswer,
                        attachmentName: '',
                        attachment: null
                    }
                }
            }
        })
    }

    formatFileSize = (size: number) => {
        if (size < 1024) {
            return size + " bytes";
        } else if (size < 1048576) {
            return (size / 1024).toFixed(2) + " Kb";
        } else if (size < 1073741824) {
            return (size / 1048576).toFixed(2) + " Mb";
        }
    }

    handleQuestionChange = (page: number) => {
        window.scrollTo(0, 0)
        this.setState({ questionNumber: page, accordionState: `${page}` })
    }

    handleChangeAccordion = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({ accordionState: isExpanded ? panel : false, questionNumber: Number(panel) });
    };

    getYouMatterPageData = async (submissionStatus: string, page: number) => {
        this.setState({ youMatterPageData: {} })
        const header = {
            "Content-Type": configJSON.dashboardContentType,
            Authorization: this.getToken(),
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.youMatterApiCallId = apiRequest.messageId;
        await this.makeApiCall(
            apiRequest.messageId,
            configJSON.dashboardGetApiMethod,
            `${configJSON.YouMatterTexts.YouMatters}?submission_status=${submissionStatus}&page=${page}`,
            header
        );
    }

    getYouMatterSections = async (youMatterId: any) => {
        const header = {
            "Content-Type": configJSON.dashboardContentType,
            Authorization: this.getToken(),
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.youMatterSectionsApiCallId = apiRequest.messageId;
        await this.makeApiCall(
            apiRequest.messageId,
            configJSON.dashboardGetApiMethod,
            `${configJSON.YouMatterTexts.YouMatters}/${youMatterId}`,
            header
        );
        return true;
    }

    getQuestions = async (sectionId: number, youMatterId: number) => {
        const header = {
            "Content-Type": configJSON.dashboardContentType,
            Authorization: this.getToken(),
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sectionDataApiCallId = apiRequest.messageId;
        await this.makeApiCall(
            apiRequest.messageId,
            configJSON.dashboardGetApiMethod,
            `${configJSON.YouMatterTexts.Section}/${sectionId}?you_matter_id=${youMatterId}`,
            header
        );
    }

    submitSectionQuestionForm = async (youMatterId: any, sectionId: any) => {
        this.setState({ openFormSubmitModal: false })
        const header = {
            Authorization: this.getToken(),
            "Content-Type": configJSON.dashboarContentType,
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sectionFormSubmitApiCallId = apiRequest.messageId;

        const sectionAnswers: sectionAnswer[] = Object.values(this.state.answers).map((answer: any) => {
            const { questionId, attachment, selectedOption, value, questionType, buckets } = answer;
            let answerObject: sectionAnswer = {
                question_id: questionId,
                mpower_checks_section_id: sectionId,
            };
        
            if (attachment) {
                answerObject["document_file"] = attachment;
            }
        
            if (selectedOption) {
                answerObject["choice_id"] = value;
                answerObject["question_type"] = questionType;
            }
        
            if (value && !selectedOption && !Array.isArray(value)) {
                answerObject["solution"] = value;
                answerObject["question_type"] = questionType;
            }
        
            if (buckets) {
                const bucketsArray: sectionAnswerBucket[] = buckets.map((bucket: AnswerBucket) => ({ bucket_id: bucket.bucket_id, choice_ids: bucket.choices }));
                answerObject["buckets"] = bucketsArray;
                answerObject["question_type"] = questionType;
            }
            return answerObject;
        });

        await this.makeApiCall(
            apiRequest.messageId,
            configJSON.dashboardPostApiMethod,
            `${configJSON.YouMatterTexts.Answers}?you_matter_id=${youMatterId}`,
            header,
            JSON.stringify({ answers: sectionAnswers})
        );
    }

    handleDragAndDropAnswer = (data: DragDropChangeData) => {
        
        const updatedAnswer = {
            "questionId": data.question_id,
            "questionType": "drag_and_drop",
            "buckets": [
                {
                    "bucket_id": data.correct_bucket_items.bucket_id,
                    "choices": data.correct_bucket_items.choices,
                    "is_correct": true,
                },
                {
                    "bucket_id": data.incorrect_bucket_items.bucket_id,
                    "choices": data.incorrect_bucket_items.choices,
                    "is_correct": false
                }
            ],
            "value": [...data.correct_bucket_items.choices, ...data.incorrect_bucket_items.choices],
            "totalOptions": this.state.sectionData.attributes.questions.data.find((question: any) => question.id == data.question_id).attributes.choice
        }

        this.setState({ answers: { ...this.state.answers, [data.question_id]: { ...updatedAnswer } } })
    }

    async makeApiCall(
        uniqueApiCallId: string,
        method: string,
        endpoint: string,
        headers: any,
        body?: any,
    ) {
        // Customizable Area Start
        let fullURL =
            endpoint.indexOf("://") === -1
                ? config.baseURL + "/" + endpoint
                : endpoint;

        let apiResponseMessage = new Message(
            getName(MessageEnum.RestAPIResponceMessage)
        );
        apiResponseMessage.addData(
            getName(MessageEnum.RestAPIResponceDataMessage),
            uniqueApiCallId
        );

        try {
            this.setState({ isLoading: true })
            let response = await fetch(fullURL, {
                method: method.toUpperCase(),
                headers: headers,
                body: body
            })
            if (response.status === 401) {
                localStorage.removeItem('userDetails')
                this.goTo('LoginForm')
            }

            this.setState({ isLoading: false })

            let responseJson = await response.json();
            if (responseJson.data || responseJson.message) {
                this.setState({ isLoading: false })
            }
            //setting Response
            apiResponseMessage.addData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
                responseJson
            );
        } catch (error) {
            runEngine.debugLog("RestApiClient Error", error);
            //setting Error
            console.log("Api Error" + JSON.stringify(error));
            apiResponseMessage.addData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
                "An error has occuured. Please try again later."
            );
        }
        this.send(apiResponseMessage);
        // Customizable Area End
    }
    // Customizable Area End
    
    // Customizable Area Start
    async handleFetchYouMatterScore() {
        const youMatterId = this.props.navigation.getParam && this.props.navigation.getParam(configJSON.YouMatterTexts.YouMatterId);
        const header = { Authorization: this.getToken(), "Content-Type": configJSON.dashboarContentType };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.youMatterScoreApiCallId = apiRequest.messageId;
        const endpoint = `${configJSON.YouMatterScoreEndpoint}?[you_matter_id]=${youMatterId}`
        await this.makeApiCall(apiRequest.messageId, configJSON.dashboardGetApiMethod, endpoint, header);
    }
    
    apiCall = async (apiData: APIPayloadType) => {
        let token = await localStorage.getItem('user_token')
    
        const { contentType, method, endPoint, body } = apiData;
        const header = {
          "Content-Type": contentType,
          "Authorization": token
        };
    
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage)
        ,JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
         method);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body))
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };

      onGetSessionstatus = async () => {
        const courseId = localStorage.getItem("particularCourse");
        this.getSessionStatusApiCallId = await this.apiCall({
          contentType: "application/json",
          method: configJSON.dashboardGetApiMethod,
          endPoint: `course_session_statuses?mind_mastery_course_id=${courseId}`
        }); 
      };

      handleParticularSession = async(sessionId:number) => {
        let body = {course_session_id: Number(sessionId),"you_matter":"completed"}
        this.patchSessionStatusApiCallId = await this.apiCall({
          contentType: "application/json",
          method: configJSON.dashboardPatchApiMethod,
          endPoint: configJSON.MindMasteryTexts.sessionStatusPatch,
          body: body,
        });
        localStorage.removeItem("courseId")
      }

    startYouMatter = (sessionId: number) => {
        const foundItem = this.state.courseSessionStatusList?.find((item) => {
            return Number(item.attributes.course_session_id) === Number(sessionId);
        });
        return foundItem || null;
    };
    getAllSessionStatus = async () => {
        this.getAllSessionStatusApiCallId = await this.apiCall({
            contentType: "application/json",
            method: configJSON.dashboardGetApiMethod,
            endPoint: "course_session_statuses/all"
        });
    };

    onGetCourseStatus = async () => {
        this.getCourseDetailApiCallId = await this.apiCall({
            contentType: "application/json",
            method: configJSON.dashboardGetApiMethod,
            endPoint: configJSON.MindMasteryTexts.courseDetails,
        });
    };

    getPerAndPostDetails = async () => {
        this.getPrePostListApiCallId = await this.apiCall({
          contentType: "application/json",
          method: configJSON.dashboardGetApiMethod,
          endPoint: "pre_post_courses",
        }); 
      };
    // Customizable Area End
}
