import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

let config = require("../../../../framework/src/config");
// Customizable Area Start
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

interface CourseStatus {
  id: string; 
  type: string;  
  attributes: {
    id: number; 
    student_id: number;
    status: string; 
    mind_mastery_course_id: number;
  };
}

interface CourseSessionStatus {
  id: string;
  type: string;
  attributes: {
    id: number;
    student_id: number;
    mind_mastery_course_id: number;
    course_session_id: number;
    you_matter_id: number;
    enhance: string;
    ponder: string;
    engage: string;
    you_matter: string;
    status: string;
  };
}

interface CoursePrePostType {
  id: number;
  name: string;
  pre_submission: boolean;
  post_submission: boolean;
}

interface CoursePrePostList {
  data: CoursePrePostType[];
}

interface CourseSessionListType {
  data: CourseSessionStatus[];
}

interface Attachment {
  attachment_type: string;
  attached: boolean;
}

interface Attributes {
  name: string;
  attachments: Attachment[];
}

export interface SessionElement {
  id: string;
  type: string;
  attributes: {
    name: string;
    attachments: [{
      attachment_type: string;
    }]
  };
}

interface CourseSessionStatus {
  id: string;
  type: string;
  attributes: {
      id: number;
      student_id: number;
      mind_mastery_course_id: number;
      course_session_id: number;
      you_matter_id: number;
      enhance: string;
      ponder: string;
      engage: string;
      you_matter: string;
      status: string;
  };
}

// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  courseStatusList: Array<CourseStatus>;
  sessionStatusList: Array<CourseSessionStatus>;
  coursePrePost: Array<CoursePrePostType>;
  courseCompletion: boolean;
  complteSectionStatus: boolean;
  courseId: string|number;
  courseSessionStatusList: Array<CourseSessionStatus>;
  sessionId: number;
  youMatterCompleted: boolean;
  youMatterNull: boolean;
  // Customizable Area End
  courseDetailsPageData: any;
  videoViewPageData: any;
  mindMasteryPageData: any;
  sessionListPageData: any;
  sessionAttachmentPageData: any;
  page: number;
  isLoading: boolean;
  videoPlayerDuration: any;
  openModal: boolean;
  modalType: "success" | "warning" | "confirm" | "schedule";
  modalInfo: string;
}
interface SS { }

export default class MindMasteryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCourseDetailApiCallId: string = "";
  postCousreStatusApiCallId: string = "";
  patchSessionStatusApiCallId: string = "";
  getSessionStatusApiCallId:string = "";
  getPrePostListApiCallId:string = "";
  getAllSessionStatusApiCallId: string="";
  // Customizable Area End
  courseDetailsApiCallId: any = "";
  courseListApiCallId: any = "";
  sessionListApiCallId: any = "";
  sessionAttachmentApiCallId: any = "";
  videoDetailApiCallId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      courseDetailsPageData: [],
      sessionStatusList:[],
      videoViewPageData: [],
      mindMasteryPageData: { data: [], page_options: {} },
      sessionListPageData: { data: [], page_options: {} },
      sessionAttachmentPageData: { data: [], page_options: {} },
      page: 1,
      isLoading: false,
      videoPlayerDuration: 0,
      openModal: false,
      modalType: 'warning',
      modalInfo: '',
      courseStatusList: [],
      coursePrePost: [],
      courseId: "",
      courseCompletion: false,
      complteSectionStatus: false,
      courseSessionStatusList: [],
      sessionId: 0,
      youMatterCompleted: false,
      youMatterNull: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            
            case this.postCousreStatusApiCallId:
              this.onGetCourseStatus();
              break;
            
            case this.patchSessionStatusApiCallId:
              this.onGetSessionstatus(Number(this.props.navigation.getParam("courseId")))
              break;
            default:
              this.handleSuccessResponse(apiRequestCallId,responseJson)
        }
        } else {
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  handleSuccessResponse = (apiCallId: string, responseJson: CoursePrePostList & CourseSessionListType) => {
    switch (apiCallId) {
      case this.getPrePostListApiCallId:
        this.setState({ coursePrePost: responseJson.data });
        break;
      case this.sessionAttachmentApiCallId:
        this.setState({ sessionAttachmentPageData: responseJson });
        break;
      case this.courseListApiCallId:
        this.setState({ mindMasteryPageData: responseJson });
        break;
      case this.sessionListApiCallId:
        this.setState({ sessionListPageData: responseJson });
        break;
      case this.getSessionStatusApiCallId:
        this.setState({ sessionStatusList: responseJson.data });
        break;
      case this.getCourseDetailApiCallId:
        this.setState({courseStatusList: responseJson.data});
      case this.getAllSessionStatusApiCallId:
        this.setState({courseSessionStatusList: responseJson.data})
  }
}

  goTo = (module: string, params: Object = {}) => {
    this.props.navigation.navigate(module, { ...params });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  getToken = () => {
    return localStorage.getItem("user_token");
  };

  handlePage = (page: number) => {
    this.setState({ page: page });
  };

  handleOpenModal = (type: "success" | "warning" | "confirm" | "schedule", info: string) => {
    this.setState({ openModal: true, modalType: type, modalInfo: info })
  }

  handleClose = () => {
    this.setState({ openModal: false })
  }

  async getCourses(page: number) {
    this.setState({ mindMasteryPageData: {} });
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.courseListApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.MindMasteryTexts.MindMasteryCourseEndPoint}?page=${page}&per_page=6`,
      header
    );
  }

  async getCourseDetails(courseId: number) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.courseDetailsApiCallId = apiRequest.messageId;

    const response = await this.videoApiCall(
      configJSON.dashboardGetApiMethod,
      `${configJSON.MindMasteryTexts.MindMasteryCourseEndPoint}/${courseId}`,
      header,
      configJSON.MindMasteryTexts.CourseDetailsPageData
    );
    return response;
  }

  getSessionList(courseId: number, page: number) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sessionListApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.MindMasteryTexts.MindMasteryCourseEndPoint}/${courseId}/course_sessions?page=${page}&per_page=6`,
      header
    );
  }

  getSessionAttachmentList(
    courseId: number,
    sessionId: number,
    attachmentType: string,
    page: number
  ) {
    this.setState({ sessionAttachmentPageData: {} })
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sessionAttachmentApiCallId = apiRequest.messageId;
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.MindMasteryTexts.MindMasteryCourseEndPoint}/${courseId}/course_sessions/${sessionId}?attachment_type=${attachmentType}&page=${page}&per_page=6`,
      header
    );
  }

  async getVideoDetails(sessionId: number, videoId: number) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.videoDetailApiCallId = apiRequest.messageId;

    const response = await this.videoApiCall(
      configJSON.dashboardGetApiMethod,
      `${configJSON.MindMasteryTexts.CourseSessions}/${sessionId}/${configJSON.MindMasteryTexts.VideoAttachments}/${videoId}`,
      header,
      configJSON.MindMasteryTexts.VideoViewPageData
    );
    return response;
  }

  async videoApiCall(
    method: string,
    endpoint: string,
    headers: any,
    state: string,
    body?: any
  ) {
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;
    let responseJson = null;

    try {
      this.setState({ isLoading: true });
      let response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body
      });
      if (response.status === 401) {
        this.goTo("LoginForm");
      }
      responseJson = await response.json();
      if (responseJson.data) {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));
    }
    this.setState({ [state]: responseJson.data } as any);
    return responseJson.data;
  }
// Customizable Area End
  async makeApiCall(
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body?: any
  ) {
    // Customizable Area Start
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;

    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId
    );

    try {
      this.setState({ isLoading: true });
      let response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body
      });
      if (response.status === 401) {
        localStorage.removeItem('userDetails')
        this.goTo("LoginForm");
      }
      let responseJson = await response.json();
      if (responseJson.data || responseJson.message) {
        this.setState({ isLoading: false });
      }
      //setting Response
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
      // if token expires then redirect to login
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "An error has occuured. Please try again later."
      );
    }
    this.send(apiResponseMessage);
    // Customizable Area End
  }
  // Customizable Area Start

  apiEditCall = async (apiData: APIPayloadType) => {
    let token = await localStorage.getItem('user_token')
    const { contentType, method, endPoint, body } = apiData;
    const header = {
      "Content-Type": contentType,
      "Authorization": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  onGetCourseStatus = async () => {
    this.getCourseDetailApiCallId = await this.apiEditCall({
      contentType: "application/json",
      method: configJSON.dashboardGetApiMethod,
      endPoint: configJSON.MindMasteryTexts.courseDetails,
    }); 
  };

  onUpdateCourseStatus = async (courseId: number) => {
    let body = {mind_mastery_course_id : courseId}
    this.postCousreStatusApiCallId = await this.apiEditCall({
      contentType: "application/json",
      method: configJSON.dashboardPatchApiMethod,
      endPoint: configJSON.MindMasteryTexts.couserStatusPatch,
      body: body,
    });
  }

  getStatus = (courseId: number) => {
    const course = this.state.courseStatusList.find((item) => item.attributes.mind_mastery_course_id === courseId);
    if (course) {
      return course.attributes.status;
    }
    return "unstarted"; 
  }
  

  handleCompleteChange = () => {
    this.setState({isLoading:false})
  }

  getListTypeValue = (listType: string): string => {
    switch (listType) {
      case 'ice_breaker':
        return 'engage';
      case 'video':
        return 'enhance';
      case 'worksheet':
        return 'ponder';
      default:
        return 'unknown';  
    }
  }
  

  handleParticularSession = async(listType:string,sessionId:number) => {
    let sessionType = this.getListTypeValue(listType)
    let body;
    if(sessionType === "engage"){
      body = {course_session_id: sessionId,"engage":"completed"}
    }else if(sessionType === "enhance"){
      body = {course_session_id: sessionId,"enhance":"completed"}
    }
    else{
      body = {course_session_id: sessionId,"ponder":"completed"}
    }
    this.patchSessionStatusApiCallId = await this.apiEditCall({
      contentType: "application/json",
      method: configJSON.dashboardPatchApiMethod,
      endPoint: configJSON.MindMasteryTexts.sessionStatusPatch,
      body: body,
    });
  }

  onGetSessionstatus = async (course: number) => {
    this.setState({courseId:course})
    this.getSessionStatusApiCallId = await this.apiEditCall({
      contentType: "application/json",
      method: configJSON.dashboardGetApiMethod,
      endPoint: `course_session_statuses?mind_mastery_course_id=${course}`
    }); 
  };

  handlePreButton = (courseId: number) => {
    let course = this.handlePreSubmmission(courseId);
    return course?.pre_submission
  }

  handlePreSubmmission = (courseId: number) => {
    const { coursePrePost} = this.state;
    const foundItem = coursePrePost.find((item) => item.id === courseId);
    return foundItem;
  }

  handleCourseSubmmisssion = () => {
    this.setState({courseCompletion: true})
  }

  getPerAndPostDetails = async () => {
    this.getPrePostListApiCallId = await this.apiEditCall({
      contentType: "application/json",
      method: configJSON.dashboardGetApiMethod,
      endPoint: "pre_post_courses",
    }); 
  };

  handleSession = (sessionId: number) => {
    const { sessionStatusList } = this.state;    
    const foundItem = sessionStatusList.find((item) => {
      return Number(item.attributes.course_session_id) === Number(sessionId);
    });
    return foundItem;
  };

  handleSessionStatus = (sessionId: number) => {
    const foundItem = this.handleSession(sessionId)
    return foundItem?.attributes.status
  }


  handleRedirection = (courseId:number,seession:number) => {
    this.setState({sessionId:seession})
    const sessionId = Number(seession);
    const foundItem = this.handleSession(sessionId);
    const youMatterId = foundItem?.attributes.you_matter_id;
    const youMatterStatus = foundItem?.attributes.you_matter;
    
    if (youMatterId === null) {
      this.setState({ youMatterNull: true }, () => {
      });
    } else if (youMatterStatus === "completed") {
      this.setState({ youMatterCompleted: true });
    }
    const type = 'upcoming_you_matter_events';
    localStorage.setItem("redirect_from", JSON.stringify({ youMatterId, type }));
    localStorage.setItem("courseId",JSON.stringify({courseId,sessionId}))
    this.setState({complteSectionStatus: true})
  }

  handleCloseSessionStatus = () => {
    const foundItem = this.handleSession(this.state.sessionId);
    const youMatterStatus = foundItem?.attributes.you_matter;
    if(youMatterStatus === "completed" || this.state.youMatterNull === true){
      this.setState({complteSectionStatus: false})
    }
    else{
      this.goTo("YouMatter");
    }
  }

  handleModalText = () => {
    const { youMatterNull, youMatterCompleted } = this.state;
  
    if (youMatterNull) {
      return configJSON.MindMasteryTexts.nullYouMatterId;
    } 
    else if (youMatterCompleted) {
      return configJSON.MindMasteryTexts.youMatterCompleted;
    } 
    else {
      return configJSON.MindMasteryTexts.sessionCompleted;
    }
  };
  
  
  handleCloseCourseStatus = () => {
    this.goTo("Mpower")
  }

  getAllSessionStatus = async () => {
    this.getAllSessionStatusApiCallId = await this.apiEditCall({
        contentType: "application/json",
        method: configJSON.dashboardGetApiMethod,
        endPoint: "course_session_statuses/all"
    });
}
  // Customizable Area End
}
