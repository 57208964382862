  // Customizable Area Start
  import React, { useEffect } from "react";
  import YouMatterController, { AnswerInputProps, AttachmentAndTextProps, OptionProps, QuestionCardProps, YouMatterQuestionPageProps } from "./YouMatterController.web";
  import SidebarLayout from "../SidebarLayout.web";
  import { makeStyles } from "@material-ui/core/styles";
  import {
    Typography,
    Box,
    Button,
    Tabs,
    Tab,
    Theme,
    Grid,
    FormControlLabel,
    RadioGroup,
    Radio,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
  } from "@material-ui/core";
  import { HapBeam, HapSpark, HapStar, YouMatterConfirm, attachmentClip, imagePlaceholder } from "../assets";
  import {
    ArrowBackIosRounded,
    ArrowForwardIosRounded,
  } from "@material-ui/icons";
  import UploadAttachment from "./UploadAttachment.web";
  import Loader from "../../../../components/src/Loader.web";
  import InfoModal from "../CommonComponents/InformationModal.web";
  import "./Items/App.css";
  import Puzzle from "./Items/Puzzle";
  import DragDropQuestion from "../../../dragdropinterface/src/DragDropQuestion.web";
  import { DragDropQuestionContent } from "../../../dragdropinterface/src/DragDropQuestionController";

  // Customizable Area End
  const configJSON = require("../config.js");

  // Customizable Area Start
  export const useStyles = makeStyles<Theme, any>((theme) => ({
    pageLayoutWrapper: {
        borderRadius: "12px",
        boxShadow: "2px 10px 16px rgba(0, 0, 0, 0.0908271)",
        background: "#FFFFFF",
        width: "calc(100vw - 397px)",
        padding: "24px",
        "@media(max-width:1279px)": {
            width: "calc(100vw - 127px)",
        },
        "@media(max-width:600px)": {
            width: "auto !important",
        },
        "@media(max-width:960px)": {
            width: "calc(100vw - 127px)",
        },
        "@media(min-width:1440px)": {
            margin: "0 auto",
            maxWidth: "1440px",
        },
    },
    tab: {
        "&.MuiTab-root": {
            width: "33.33%",
            fontSize: "16px",
            lineHeight: "24px",
            textTransform: "capitalize",
            color: "#0056A3",
            fontFamily: "Poppins",
            fontWeight: 400,
            maxWidth: "none",
            [theme.breakpoints.down("xs")]: {
                fontSize: "14px",
            },
        },
        "&.MuiTab-root.Mui-selected": {
            borderRadius: "8px",
            fontSize: "16px",
            lineHeight: "27px",
            fontWeight: 600,
            color: "white",
            textTransform: "capitalize",
            backgroundColor: "#0056A3",
            fontFamily: "Poppins",

            [theme.breakpoints.down("xs")]: {
                fontSize: "14px",
            },
        },
    },
    tabContentWrapper: {
        paddingTop: "48px",
    },
    tabs: {
        "&.MuiTabs-root": {
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
            border: "0.2px solid #e3e3e3",
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
    },
    questionNumber: {
        color: "white",
        minHeight: "56px",
        borderRadius: "8px",
        minWidth: "56px",
        backgroundColor: "#0056A3",
        fontSize: "20px",
        fontFamily: "Poppins",
        fontWeight: 500,
        display: "flex",
        marginRight: "16px",
        alignItems: "center",
        justifyContent: "center",
    },
    questionCard: {
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
        padding: "8px",
        border: "0.2px solid #e3e3e3",
        marginBottom: (props) => (props.isListView ? "0px" : "16px"),
    },
    questionText: {
        fontWeight: 500,
        lineHeight: "24px",
        fontSize: "16px",
        color: "#3B3B3B",
        fontFamily: "Poppins",
    },
    attachmentBtn: {
        "&.MuiButton-root": {
            height: "36px",
            width: "144px",
            border: "1px solid #0056A3",
            borderRadius: "8px",
            backgroundColor: "white",
            marginLeft: "auto",
            display: "flex",
        },
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "500",
            color: "#0056A3",
            fontFamily: "Poppins",
            "& img": {
                marginRight: "10px",
            },
        },
    },
    selectOptionText: {
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "27px",
        color: "#0056A3",
        marginBottom: "22px",
    },
    optionWrapper: {
        borderRadius: "8px",
        padding: (props) => (props.isListView ? "4px" : "8px"),
        "&:has(.Mui-checked)": {
            border: "0.75px solid #0056A3",
        },
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
        border: "0.2px solid #e3e3e3",
    },
    optionFormLabel: {
        "&.MuiFormControlLabel-root": {
            width: "100%",
            justifyContent: "space-between",
            marginLeft: "0px",
        },
    },
    radioGrp: {
        "&.MuiFormGroup-root": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
        },
    },
    submitForm: {
        "@media(max-width:450px)": {
            width: "150px !important",
        },
        "&.MuiButton-root": {
            height: "48px",
            width: "208px",
            borderRadius: "12px",
            backgroundColor: "#0056A3",
            marginLeft: (props) => (props.isListView ? "auto" : "0"),
            display: "block",
        },
        "& .MuiButton-label": {
            textTransform: "capitalize",
            color: "white",
            fontSize: "16px",
            fontFamily: "Poppins",
            "@media(max-width:450px)": {
                fontSize: "12px",
            },
        },
        "&.MuiButton-root.Mui-disabled": {
            backgroundColor: "#0056a380",
        },
    },
    navigationWrapper: {
        width: "125px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    actionWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "36px",
    },
    navigarionBtn: {
        "&.MuiButton-root": {
            width: "50px",
            height: "50px",
            borderRadius: "8px",
            backgroundColor: "#0056A3",
            border: "1px solid #20202066",
            minWidth: "auto",
        },
        "& .MuiButton-label": {
            color: "white",
            fontFamily: "Poppins",
        },
        "&.MuiButton-root.Mui-disabled": {
            border: "1px solid gray",
            backgroundColor: "#e3e3e3",
        },
    },
    accordionSummary: {
        "& .MuiAccordionSummary-content": {
            padding: "0",
            minHeight: "auto",
            display: "block",
            margin: "7px 0",
        },
        "&.MuiAccordionSummary-root": {
            padding: "0",
        },
    },
    accordion: {
        "&.MuiAccordion-root": {
            boxShadow: "none",
        },
        "&.MuiAccordion-root:before": {
            display: "none",
        },
    },
    accordionDetails: {
        "&.MuiAccordionDetails-root": {
            display: "block",
            padding: "10px 0 0 0",
        },
        '& .choosedItems': {
          display: 'flex',
          height: '100%',
          width: '100%',
          margin: 0,
          padding: '24px 0',
          gap: 24,
          '& .droppedItems': {
              width: '100%',
              padding: '0 0 0 24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              '& .droppedItemDelete': {
                  minWidth: '50px',
                  padding: 0,
                  textTransform: 'capitalize',
                  color: '#0056a3'
              },
              '& .droppedItemText': {
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  color: '#0057A4'
              }
          }
      }
    },
    subjectiveInput: {
        "&.MuiFormControl-root": {
            width: "100%",
        },
        "& .MuiInputBase-input": {
            padding: "24px 22px",
            background: "#FDFDFD",
            border: "0.2px solid #0056A3",
            borderRadius: "8px",
        },
        "& .MuiInput-underline:before": {
            display: "none",
        },
        "& .MuiInput-underline:after": {
            display: "none",
        },
    },
    questionImagePlaceholder: {
        background: "#FDFDFD",
        border: "0.2px solid #0056A3",
        borderRadius: "8px",
        backgroundImage: `url(${imagePlaceholder})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        maxHeight: "480px",
        padding: "0 10px",
        margin: "20px 0 30px 0",
        "&>img": {
            maxWidth: "100%",
            width: "100%",
            height: "100%",
            objectFit: "contain",
        },
    },
    option: {
        minHeight: (props) => (props.isListView ? "48px" : "44px"),
        minWidth: (props) => (props.isListView ? "48px" : "44px"),
        borderRadius: "8px",
        backgroundColor: "#0056A3",
        color: "white",
        fontSize: "16px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "20px",
        fontFamily: "Poppins",
    },
    radioLabel: {
        display: "flex",
        alignItems: "center",
    },
    yourSolution: {
        fontSize: "14px",
        marginLeft: "48px",
        "&>span": {
            color: "#0056A3",
            fontFamily: "Poppins",
        },
    },
    optionContent: {
        fontSize: "16px",
        overflow: "hidden",
        fontWeight: 500,
        lineHeight: "24px",
        display: "-webkit-box",
        "-webkitLineClamp": 1,
        "-webkitBoxOrient": "vertical",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
        "&:hover": {
            "-webkitBoxOrient": "inherit",
            wordBreak: "break-word",
        },
    },
    attachmentName: {
        color: "#0056A3",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 300,
        marginTop: "10px",
        width: "100%",
        textAlign: "end",
    },
  }));

  const componentStyles = makeStyles({
    radioUnchecked: {
      height: "24px",
      width: "24px",
      borderRadius: "50%",
      border: "1px solid #0056A3",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    radioChecked: {
      width: "15.4px",
      height: "15.4px",
      borderRadius: "50%",
      backgroundColor: "#0056A3",
    },
  });

  export const Icon = () => {
    const classes = componentStyles();
    return <Box data-test-id="box" className={classes.radioUnchecked}></Box>;
  };

  export const CheckedIcon = () => {
    const classes = componentStyles();
    return (
      <Box data-test-id="box" className={classes.radioUnchecked}>
        <Box className={classes.radioChecked}></Box>
      </Box>
    );
  };

  const optionName = (id: number) => {
    switch (id) {
      case 1:
        return configJSON.YouMatterTexts.A;
      case 2:
        return configJSON.YouMatterTexts.B;
      case 3:
        return configJSON.YouMatterTexts.C;
      case 4:
        return configJSON.YouMatterTexts.D;
      case 5:
        return configJSON.YouMatterTexts.E;
      case 6:
        return configJSON.YouMatterTexts.F;
    }
  };
  export const Option = (props: OptionProps) => {
    const classes = useStyles(props);
    return (
      <Box className={classes.radioLabel} data-test-id="box">
        <Box className={classes.option}>{optionName(props.option)}</Box>
        <Typography className={classes.optionContent}>{props.content}</Typography>
      </Box>
    );
  };

  export const renderOptionsText: (questionType: string) => string = (questionType) => {
    let selectOptionText:string;
    switch(questionType){
      case configJSON.QuestionTypes.TextBased:
        selectOptionText = configJSON.YouMatterTexts.Enter;
        break;
      case configJSON.QuestionTypes.DragAndDrop:
        selectOptionText = configJSON.YouMatterTexts.DragAndDropOptionsLabelTxt
        break;
      case configJSON.QuestionTypes.Puzzle:
        selectOptionText = "Solve the Puzzle";
        break;
      default: 
        selectOptionText = configJSON.YouMatterTexts.Select+" "+configJSON.YouMatterTexts.YourSolution
    }
    return selectOptionText;
  }

  export const AttachmentAndText = (props: AttachmentAndTextProps) => {
    const classes = useStyles(props);
    const {
      image,
      questionId,
      openAttachmentModal,
      selectedAnswers,
      index,
      type,
    } = props;
    
    return (
      <>
        {type === configJSON.QuestionTypes.TextBased && (
          <>
            <Button
              data-test-id="attachmentOpener"
              className={classes.attachmentBtn}
              onClick={() => openAttachmentModal(questionId, index)}
            >
              <img src={attachmentClip} alt="clip_img" />
              {configJSON.YouMatterTexts.AttachFile}
            </Button>
            <Typography className={classes.attachmentName}>
              {selectedAnswers[questionId]?.attachmentName}
            </Typography>
          </>
        )}
        {image && type !== configJSON.QuestionTypes.Puzzle && (
          <Box className={classes.questionImagePlaceholder}>
            <img
              style={{ width: "100%" }}
              src={image}
              alt="question details pic"
            />{" "}
          </Box>
        )}
        <Typography className={classes.selectOptionText} data-test-id="optionsLabel">
          {renderOptionsText(type)}
        </Typography>
      </>
    );
  };

  export const QuestionCardComponent = (props: QuestionCardProps) => {
    const classes = useStyles(props);
    const { isListView, selectedAnswers, index, questionInfo } = props;
    const questionId = questionInfo?.attributes.id;
    const question = questionInfo?.attributes.text;
    const questionType = questionInfo?.attributes.question_type;
    const options = questionInfo?.attributes.choice;
    const chosenAns =
      selectedAnswers && Number(selectedAnswers[questionId]?.value);
    const chosenOption =
      selectedAnswers && selectedAnswers[questionId]?.selectedOption;
    const ansObj = options?.filter((element) => {
      return Number(element.id) === chosenAns;
    });
    const showSelectedAns =
      isListView &&
      questionType !== configJSON.QuestionTypes.TextBased &&
      !isNaN(chosenAns);
    return (
      <Box className={classes.questionCard} data-test-id="box">
        <Box className={classes.questionNumber}>{`Q${index}.`}</Box>
        <Typography className={classes.questionText} data-test-id="questionNumber">{`${question}`}</Typography>
        {showSelectedAns && (
          <Typography className={classes.yourSolution}>
            {configJSON.YouMatterTexts.YourSolution}.{" "}
            <span data-test-id="choosenSolution">
              {chosenOption && ansObj && `(${chosenOption}) ${ansObj[0]?.content} `}
            </span>
          </Typography>
        )}
      </Box>
    );
  };

  export const AnswerInput = (props: AnswerInputProps) => {
    const classes = useStyles(props);
    const {
      questionId,
      handleOptionAnswer,
      handleSubjectiveQuestion,
      handleDragAndDropAnswer,
      options,
      type,
      isListView,
      selectedAnswers,
      image,
      rows,
      columns,
      no_of_pieces,
      refImage,
      buckets
    } = props;

    const ansValue =
      selectedAnswers && selectedAnswers[Number(questionId)]?.value;

    const handleTextBased = (event: React.ChangeEvent<HTMLInputElement>) => {
      const answer = (event.target as HTMLInputElement).value;
      handleSubjectiveQuestion(
        questionId,
        answer,
        configJSON.QuestionTypes.TextBased
      );
    };

    const PuzzleCondition = () => {
      useEffect(() => {
        const handlePuzzleCompletion = () => {
          handleSubjectiveQuestion(
            questionId,
            true,
            configJSON.QuestionTypes.Puzzle
          );
        };

        let img = image;
        const divElement: any = document.querySelector("#puzzalContainer");
        if (img) {
          const newPuzzle = new Puzzle({
            img: img,
            width: divElement?.offsetWidth,
            height: divElement?.offsetHeight,
            npieces: (no_of_pieces || 4),
            idiv: "forPuzzle",
            onCompletion: handlePuzzleCompletion,
          });
          newPuzzle.runPuzzle();
        }
      }, [image]);

      const style = ` 

      
      .jigsaw-puzzle {
          position: relative;
          background-image: url(${refImage});
          background-size: contain;
      }
      
      
      `;

      return (
        <>
          {type == configJSON.QuestionTypes.Puzzle ? (
           <div style={{display:"flex",flexDirection:"column",alignItems:"center",width:"100%"}}>

              <style>{style}</style>


              {ansValue !== true  && (     <img
                  src={image}
                  alt="no image"
                  style={{height:200,width:200,paddingBottom:25}}
                />)}
              <div
                id="puzzalContainer"
                style={{
                  height: "500px",
                  width: "900px",
                  margin: "auto",
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 0,
                  boxShadow: "0 5px 10px 2px rgba(0, 0, 0, 0.25)",
                  display: ansValue === true ? "none" : "block",
                }}
              >


                <div className="puzzalImage">
                  <div id="forPuzzle"></div>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  margin: "auto",
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: 2,
                  boxShadow: "0 5px 10px 2px rgba(0, 0, 0, 0.25)",
                  display: ansValue !== true ? "none" : "block",
                }}
              >
                <img src={image} alt="no image" style={{ width: "100%" }} />
              </div>
            </div>
          ) : (
            <TextField
              data-test-id="subjective-field"
              onChange={handleTextBased}
              value={ansValue}
              className={classes.subjectiveInput}
              multiline
              minRows={6}
              placeholder={configJSON.YouMatterTexts.WriteYourAnswerHere}
            />
          )}
        </>
      );
    };

    const handleChangeOptionOrTextField = (
      event: React.ChangeEvent<HTMLInputElement>,
      questionType: string
    ) => {
      const answer = Number((event.target as HTMLInputElement).value);
      const optionIndex = options?.findIndex(
        (element) => Number(element.id) === answer
      );
      const ansObj = options?.filter((element) => {
        return Number(element.id) === answer;
      });
      const selectedOption = optionName(optionIndex + 1) as any;
      handleOptionAnswer(questionId, ansObj, selectedOption, questionType);
    };

    if(type === configJSON.QuestionTypes.DragAndDrop){
      const questionData: DragDropQuestionContent = { id: questionId, options: options, buckets: buckets, answers: selectedAnswers[questionId] };
      return <DragDropQuestion navigation={props.navigation} id={props.id} questionData={questionData} isListView={isListView} handleDragDropChange={handleDragAndDropAnswer}/>
    }

  
    return (
      <>
        {type === configJSON.QuestionTypes.Objective || type === configJSON.QuestionTypes.TrueFalse ? (
          <RadioGroup
            data-test-id="radio-grp"
            className={classes.radioGrp}
            value={`${ansValue}`}
            onChange={(e) => handleChangeOptionOrTextField(e, type)}
          >
            <Grid container spacing={2}>
              {options?.map((element: any, index: number) => (
                <Grid
                  key={element.id}
                  item
                  lg={isListView ? 3 : 12}
                  xl={isListView ? 3 : 12}
                  sm={12}
                  xs={12}
                  md={12}
                >
                  <Box className={classes.optionWrapper}>
                    <FormControlLabel
                      className={classes.optionFormLabel}
                      value={`${element.id}`}
                      labelPlacement="start"
                      control={
                        <Radio
                          color="primary"
                          icon={<Icon />}
                          checkedIcon={<CheckedIcon />}
                        />
                      }
                      label={
                        <Option
                          option={index + 1}
                          content={element.content}
                          isListView={isListView}
                        />
                      }
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        ) : (
          PuzzleCondition()
        )}
      </>
    );
  };

  export const YouMatterQuestionPage = (props: YouMatterQuestionPageProps) => {
    const classes = useStyles(props);

    const a11yProps = (index: any) => {
      return {
        id: `you-matter-tab-${index}`,
        "aria-controls": `you-matter-tabpanel-${index}`,
      };
    };

    const {
      data,
      sectionsList,
      currentTab,
      handleTab,
      isListView,
      handleSubjectiveQuestion,
      questionNumber,
      selectedAnswers,
      accordionState,
      openUploadAttachment,
      handleOptionAnswer,
      handleChangeAccordion,
      handleQuestionChange,
      handleSubmit,
      handleDragAndDropAnswer,
    } = props;
    const questions = data?.attributes?.questions?.data;
    const currentPage = questionNumber - 1;
    const ansLength = Object.values(selectedAnswers).filter(
      (item: any) => {
      if(item.questionType === 'drag_and_drop') {
        return item.totalOptions.length === item.value.length
      } else {
        return item.value !== undefined && item.value !== ""
      }
    }).length;

    return (
      <Box className={classes.pageLayoutWrapper}>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          data-test-id="tabs"
          onChange={handleTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          {sectionsList.map((_element: any, index: number) => {
            return (
              <Tab
                key={_element.id}
                className={classes.tab}
                disabled={index !== currentTab}
                label={`section - ${index + 1}`}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
        <Box className={classes.tabContentWrapper}>
          {!isListView && (
            <>
              {questions
                ?.filter((_question: any, index: any) => index === currentPage)
                ?.map((question: any) => (
                  <React.Fragment key={question.id}>
                    <QuestionCardComponent
                      questionInfo={question}
                      selectedAnswers={selectedAnswers}
                      isListView={false}
                      index={questionNumber}
                    />
                    <AttachmentAndText
                      type={question.attributes?.question_type}
                      openAttachmentModal={openUploadAttachment}
                      index={questionNumber}
                      selectedAnswers={selectedAnswers}
                      questionId={question.id}
                      image={question.attributes?.document_file}
                    />
                    <AnswerInput
                      selectedAnswers={selectedAnswers}
                      handleSubjectiveQuestion={handleSubjectiveQuestion}
                      handleOptionAnswer={handleOptionAnswer}
                      handleDragAndDropAnswer={handleDragAndDropAnswer}
                      questionId={question.id}
                      options={question.attributes?.choice}
                      type={question.attributes?.question_type}
                      rows={question.attributes?.dimensions?.rows}
                      columns={question.attributes?.dimensions?.columns}
                      no_of_pieces={question.attributes?.no_of_pieces}
                      buckets={question.attributes?.buckets}
                      isListView={false}
                      navigation={props.navigation}
                      id={props.id}
                      image={question.attributes?.document_file}
                      refImage={question.attributes?.reference_document_file}
                    />
                  </React.Fragment>
                ))}
            </>
          )}

          {isListView &&
            questions?.map((element: any, index: number) => {
              return (
                <Accordion
                  data-test-id="accordion"
                  key={element.id}
                  className={classes.accordion}
                  expanded={accordionState === `${index + 1}`}
                  onChange={handleChangeAccordion(`${index + 1}`)}
                >
                  <AccordionSummary className={classes.accordionSummary}>
                    <QuestionCardComponent
                      questionInfo={element}
                      index={index + 1}
                      selectedAnswers={selectedAnswers}
                      isListView={true}
                    />
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <AttachmentAndText
                      type={element?.attributes?.question_type}
                      selectedAnswers={selectedAnswers}
                      openAttachmentModal={openUploadAttachment}
                      index={index + 1}
                      questionId={element.id}
                      image={element?.attributes?.document_file}
                    />
                    <AnswerInput
                      selectedAnswers={selectedAnswers}
                      handleSubjectiveQuestion={handleSubjectiveQuestion}
                      handleOptionAnswer={handleOptionAnswer}
                      questionId={element.id}
                      options={element?.attributes?.choice}
                      type={element?.attributes?.question_type}
                      isListView={true}
                      navigation={props.navigation}
                      id={props.id}
                      buckets={element?.attributes?.buckets}
                      no_of_pieces={element.attributes?.no_of_pieces}
                      image={element.attributes?.document_file}
                      handleDragAndDropAnswer={handleDragAndDropAnswer}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}

          <Box className={classes.actionWrapper}>
            <Button
              data-test-id="submitForm"
              disabled={ansLength < questions?.length}
              className={classes.submitForm}
              onClick={handleSubmit}
            >
              {configJSON.YouMatterTexts.SubmitYourMatter}
            </Button>
            {!isListView && (
              <Box className={classes.navigationWrapper}>
                <Button
                  data-test-id="navigators"
                  disabled={questionNumber === 1}
                  className={classes.navigarionBtn}
                  onClick={() => handleQuestionChange(currentPage)}
                >
                  <ArrowBackIosRounded />
                </Button>
                <Button
                  disabled={questionNumber === questions?.length}
                  className={classes.navigarionBtn}
                  onClick={() => handleQuestionChange(props.questionNumber + 1)}
                >
                  <ArrowForwardIosRounded />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  export default class YouMatterQuestions extends YouMatterController {
    componentDidUpdate(prevProps: any, prevState: any): void {
      const sections = this.state.sections;
      const currentTab = this.state.sectionTab;
      if (this.props.navigation.getParam) {
        const youMatterId = this.props.navigation.getParam("youMatterId");
        if (prevState.sectionTab !== this.state.sectionTab) {
          this.getQuestions(sections[currentTab]?.id, youMatterId);
        }
      }
    }

    async componentDidMount(): Promise<void> {
      window.scrollTo(0, 0);
      await this.getAllSessionStatus();
      await this.onGetCourseStatus();
      const redirectedYouMatterMPower = localStorage.getItem("courseId");
      if (redirectedYouMatterMPower) {
        const youMatter = JSON.parse(redirectedYouMatterMPower)
        if (youMatter) {
          this.setState({ sessionIdStore: JSON.parse(redirectedYouMatterMPower) })
        }
      }

      if (this.props.navigation.getParam) {
        const youMatterId = this.props.navigation.getParam(
          configJSON.YouMatterTexts.YouMatterId
        );
        const response = await this.getYouMatterSections(youMatterId);
        if (response) {
          const sections = this.state.sections;
          await this.getQuestions(
            sections[this.state.sectionTab].id,
            youMatterId
          );
        }
      }
    }

    getBadgeImage() {
      const youMatterScore = this.state.youMatterScore?.you_matter_score;
      if (!youMatterScore) {
          return;
      }

      const scoreNumbers = youMatterScore.match(/\d+/g);
      if (!scoreNumbers || scoreNumbers.length === 0) {
          return;
      }
      const score = Number(scoreNumbers[0]);
      
      let badgeImage:string = "";
      if (score >= 0 && score <= 35) {
          badgeImage = HapSpark;
      } else if (score >= 36 && score <= 75) {
          badgeImage = HapBeam;
      } else if (score >= 76 && score <= 100) {
          badgeImage = HapStar;
      }
      return badgeImage;
    }

    render() {
      const pageData = this.state.sectionData;
      const questions = pageData?.attributes?.questions?.data;
      const sections = this.state.sections;
      const currentTab = this.state.sectionTab;
      const sectionId = sections[currentTab]?.id;
      const youMatterId =
        this.props.navigation.getParam &&
        this.props.navigation.getParam(configJSON.YouMatterTexts.YouMatterId);
      const youMatterProps = {
        navigation: this.props.navigation,
        id: this.props.id,
        data: pageData,
        handleSubmit: this.handleOpenDialog,
        handleSubjectiveQuestion: this.handleSubjectiveQuestion,
        sectionsList: this.state.sections,
        selectedAnswers: this.state.answers,
        handleOptionAnswer: this.handleQuestionAnswer,
        isListView: this.state.listView,
        openUploadAttachment: this.handleOpenDropZone,
        handleQuestionChange: this.handleQuestionChange,
        questionNumber: this.state.questionNumber,
        handleChangeAccordion: this.handleChangeAccordion,
        accordionState: this.state.accordionState,
        currentTab: this.state.sectionTab,
        handleTab: this.handleSectionTabChange,
        handleDragAndDropAnswer:this.handleDragAndDropAnswer
      };

      const uploadAttachmentProps = {
        states: this.state,
        handleCloseDropZone: this.handleCloseDropZone,
        handleOnDrop: this.handleUploadAttachment,
        handleCancel: this.handleCancelUpload,
        handleDone: this.handleAttachmentUploadDone,
      };

      const successfulProps = {
        confirmImage: YouMatterConfirm,
        successImage: this.getBadgeImage(),
        info:
          this.state.modalType === configJSON.YouMatterTexts.Success
            ? `${configJSON.YouMatterTexts.YouMatterScoreText} ${this.state.youMatterScore?.badge}!`
            : `${configJSON.YouMatterTexts.ConfirmMsg} You Matter?`,
        modalType: this.state.modalType,
        submit: () => this.submitSectionQuestionForm(youMatterId, sectionId),
        open: this.state.openFormSubmitModal,
        closeModal: this.handleCloseSubmitSuccessModal,
      };
      return (
        <>
          <SidebarLayout
            {...this.props}
            youMatterTitle={this.state.youMatterTitle}
            youMatterSectionId={this.state.sectionTab + 1}
            handleViewChange={this.handleQuestionViewChange}
            isQuestionView={true}
            isListView={this.state.listView}
            totalQuestions={questions?.length}
            questionNumber={this.state.questionNumber}
            handleQuestionChange={this.handleQuestionChange}
          >
            {this.state.isLoading ? (
              <Loader loading={this.state.isLoading} />
            ) : null}
            <YouMatterQuestionPage
              data-test-id="question-page"
              {...youMatterProps}
            />

            <UploadAttachment {...uploadAttachmentProps} />
            <InfoModal data-test-id="modal" {...successfulProps} />
          </SidebarLayout>
        </>
      );
    }
  }
  // Customizable Area End
